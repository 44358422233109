import { Button, Dropdown, MenuProps, message, Input, Tooltip } from "antd";
import * as React from "react";
import ProductSelect from "../ProductSelect";
import { products, teams, states, teamsID } from "../../utils/sergipao";
import {
  validateEmail,
  validateCpf,
  validateCidade,
  validateCep,
  validateTelefone,
} from "../../utils/checks";
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import PopupCheckout from "../PopupCheckout";
import { redirectProducts } from "../../utils/sergipao";
import RedirectCard from "../RedirectCard/index";

import "./main.sass";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ImageCard from "../ImageCard";

interface Team {
  key: string;
  label: string;
  icon: JSX.Element;
}

interface State {
  key: string;
  label: string;
}

interface Product {
  key: string;
  name: string;
  price: number;
  description?: string;
  image: string;
}

interface Form {
  visible: boolean;
  nome: string;
  endereco: string;
  numero: string;
  telefone: string;
  cidade: string;
  cep: string;
  senha: string;
  verificarSenha: string;
}

interface apiResponse {
  link: string;
  price: number;
  discount: number | null;
  priceWithDiscount: number;
}

interface Coupon {
  code: string;
  isValid: boolean | null;
  isLoading: boolean;
}

const api = "https://api-resellers.ittv.com.br";

const Main = () => {
  const [productKey, setProductKey] = React.useState<string>("");
  const [selectedTeam, setSelectedTeam] = React.useState<string>("-1");
  const [selectedState, setSelectedState] = React.useState<string>("-1");
  const [loadingNext, setLoadingNext] = React.useState<boolean>(false);
  const [loadingCheckout, setLoadingCheckout] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [cpf, setCpf] = React.useState<string>("");
  const [coupon, setCoupon] = React.useState<Coupon>({
    code: "",
    isValid: null,
    isLoading: false,
  });
  const [isFormInfoActive, setFormInfoActive] = React.useState<boolean>(true);
  const [isFormCreateActive, setFormCreateActive] =
    React.useState<boolean>(true);
  const [popupOpen, setPopupOpen] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<Form>({
    visible: false,
    nome: "",
    endereco: "",
    telefone: "",
    numero: "",
    cidade: "",
    cep: "",
    senha: "",
    verificarSenha: "",
  });
  const [apiResponse, setApiResponse] = React.useState<apiResponse>({
    link: "",
    discount: null,
    price: 0,
    priceWithDiscount: 0,
  });

  const [isAccordionActive, setAccordionActive] =
    React.useState<boolean>(false);

  const handleCoupon = async () => {
    if (!coupon.code) {
      message.error("Digite um cupom");
      return;
    }
    setCoupon({ ...coupon, isLoading: true });
    if (coupon.code) {
      setTimeout(async () => {
        setCoupon({ ...coupon, isLoading: true });
        await axios
          .get(api + "/sergipao/coupon/" + coupon.code)
          .then((response) => {
            if (response.data.discount > -1) {
              setCoupon({ ...coupon, isValid: true, isLoading: false });
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              setCoupon({ ...coupon, isValid: false, isLoading: false });
            } else {
              message.error("Erro ao validar cupom");
            }
          });
      }, 1000);
    } else {
      message.error("Digite um cupom");
      setCoupon({ ...coupon, isLoading: false });
    }
  };

  const mockHandleCoupon = async () => {
    if (!coupon.code) {
      message.error("Digite um cupom");
      return;
    }
    setCoupon({ ...coupon, isLoading: true });
    setTimeout(() => {
      if (coupon.code === "CUPOMTESTE") {
        setApiResponse({ ...apiResponse, discount: 10 });
        setCoupon({ ...coupon, isValid: true, isLoading: false });
      } else {
        setApiResponse({ ...apiResponse, discount: null });
        setCoupon({ ...coupon, isValid: false, isLoading: false });
      }
    }, 1000);
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    setSelectedTeam(e.key);
  };

  const handleStateClick: MenuProps["onClick"] = (e) => {
    setSelectedState(e.key);
  };

  const handleCheckout = async () => {
    if (form.visible) {
      setLoadingCheckout(true);
      if (!form.senha) {
        message.error("Digite sua senha");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!form.verificarSenha) {
        message.error("Digite sua senha novamente");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (form.senha !== form.verificarSenha) {
        message.error("As senhas não coincidem");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!email) {
        message.error("Digite um email");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!validateEmail(email)) {
        message.error("Email inválido");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!cpf) {
        message.error("Digite um CPF");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!validateCpf(cpf)) {
        message.error("CPF inválido");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!form.nome) {
        message.error("Informe o seu nome");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!form.endereco) {
        message.error("Informe o endereço");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!form.numero) {
        message.error("Informe o número");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!form.telefone) {
        message.error("Informe o telefone");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!validateTelefone(form.telefone)) {
        message.error("Telefone inválido");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!form.cidade) {
        message.error("Informe a cidade");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!validateCidade(form.cidade)) {
        message.error("Cidade inválida");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!form.cep) {
        message.error("Informe o CEP");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (!validateCep(form.cep)) {
        message.error("CEP inválido");
        setFormCreateActive(true);
        setLoadingCheckout(false);
        return;
      }
      if (selectedState === "-1") {
        message.error("Selecione um estado");
        setFormCreateActive(true);
        return;
      }
      message.info("Criando...");
      if (
        productKey === products.find((e) => e.name.includes("Beach"))?.key ||
        productKey === products.find((e) => e.name.includes("Alagoas"))?.key
      ) {
        await axios
          .post(api + "/alagoano/create", {
            firstName: form.nome,
            username: email.toLowerCase(),
            email: email.toLowerCase(),
            cpf: cpf,
            password: form.senha,
            address: form.endereco,
            phone: form.telefone,
            city: form.cidade,
            state: showState(selectedState),
            number: form.numero,
            cep: form.cep,
            product: products[+productKey - 1].token,
          })
          .then((res) => {
            if (res.status === 201) {
              setApiResponse(res.data);
              setLoadingNext(false);
              setPopupOpen(true);
            }
          });
      } else {
        await axios
          .post(api + "/sergipao/create", {
            firstName: form.nome,
            username: email.toLowerCase(),
            email: email.toLowerCase(),
            cpf: cpf,
            password: form.senha,
            address: form.endereco,
            phone: form.telefone,
            city: form.cidade,
            state: showState(selectedState),
            number: form.numero,
            cep: form.cep,
            product: products[+productKey - 1].token,
            productName: products[+productKey - 1].name,
            team: Object.entries(teamsID)[+selectedTeam][0],
            coupon: coupon.code,
          })
          .then((res) => {
            if (res.status === 201) {
              setApiResponse(res.data);
              setLoadingNext(false);
              setPopupOpen(true);
            }
          });
      }
    }
  };

  const handleNextButton = async () => {
    setFormInfoActive(false);
    if (
      selectedTeam === "-1" &&
      !(
        productKey === products.find((e) => e.name.includes("Beach"))?.key ||
        productKey === products.find((e) => e.name.includes("Alagoas"))?.key
      )
    ) {
      message.error("Selecione um time do coração");
      setFormInfoActive(true);
      return;
    }
    if (productKey === "") {
      message.error("Selecione um plano");
      setFormInfoActive(true);
      return;
    }
    if (!email) {
      message.error("Digite um email");
      setFormInfoActive(true);
      return;
    }
    if (!validateEmail(email)) {
      message.error("Email inválido");
      setFormInfoActive(true);
      return;
    }
    if (!cpf) {
      message.error("Digite um CPF");
      setFormInfoActive(true);
      return;
    }
    if (!validateCpf(cpf)) {
      message.error("CPF inválido");
      setFormInfoActive(true);
      return;
    }
    setLoadingNext(true);
    message.info("Verificando...");

    if (
      productKey === products.find((e) => e.name.includes("Beach"))?.key ||
      productKey === products.find((e) => e.name.includes("Alagoas"))?.key
    ) {
      await axios
        .post(api + "/alagoano/verify", {
          email,
          cpf,
          product: products[+productKey - 1].token,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              message.info(
                "Este e-mail é elegível a esta oferta. Por favor cadastre-se"
              );
              setLoadingNext(false);
              setFormInfoActive(false);
              setFormCreateActive(true);
              setForm({ ...form, visible: true });
            } else if (err.response.status === 400) {
              message.error(
                "Este e-mail não é elegível a esta oferta. Por favor use outro e-mail."
              );
              setLoadingNext(false);
              setFormInfoActive(true);
            } else {
              message.error(
                "Erro inesperado. Por favor, tente novamente mais tarde."
              );
              setLoadingNext(false);
              setFormInfoActive(true);
            }
          } else {
            message.error(
              "Erro inesperado. Por favor, atualize a página e tente novamente."
            );
            setLoadingNext(false);
            setFormInfoActive(true);
          }
        });
    } else {
      await axios
        .post(api + "/sergipao/verify", {
          email,
          cpf,
          team: Object.entries(teamsID)[+selectedTeam][0],
          product: products[+productKey - 1].token,
          productName: products[+productKey - 1].name,
          coupon: coupon.code,
        })
        .then((res) => {
          if (res.status === 201) {
            setApiResponse(res.data);
            setLoadingNext(false);
            setPopupOpen(true);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              message.info(
                "Não há um cadastro com este email. Por favor, cadastre-se"
              );
              setLoadingNext(false);
              setFormInfoActive(false);
              setFormCreateActive(true);
              setForm({ ...form, visible: true });
            } else {
              message.error(
                "Erro inesperado. Por favor, tente novamente mais tarde."
              );
              setLoadingNext(false);
              setFormInfoActive(true);
            }
          } else {
            message.error(
              "Erro inesperado. Por favor, atualize a página e tente novamente."
            );

            setLoadingNext(false);
            setFormInfoActive(true);
          }
        });
    }
  };

  const showTeam = (t: string) => {
    const team = teams?.find((team) => team?.key === t) as Team | undefined;
    return team?.label ?? "Selecione um time";
  };

  const showState = (s: string) => {
    const state = states?.find((state) => state?.key === s) as
      | State
      | undefined;
    return state?.label ?? "Selecione um estado";
  };

  // const getGeolocationByIP = () => {
  //   axios
  //     .get("https://ipapi.co/json/")
  //     .then((res) => {
  //       console.log(res.data)
  //       // const state = states.find((s) => s.label === res.data.region);
  //       // if (state) {
  //       //   setSelectedState(state.key);
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // React.useEffect(() => {
  //   getGeolocationByIP();
  // }, [selectedTeam]);

  return (
    <div id="mainContainer">
      {/* <ProductSelect
        product={productKey}
        setProduct={setProductKey}
        isFormInfoActive={isFormInfoActive}
      /> */}
      <h1 style={{
        margin: 20
      }}>Escolha o seu plano</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-evenly",
          margin:"20px 0 50px 0",
          gap: 30
        }}
      >
        {redirectProducts.map((product) => (
          <ImageCard data={product} />
          // <RedirectCard data={product} />
        ))}
      </div>
      {/* <span>Time do coração</span>
      <Dropdown
        disabled={
          !isFormInfoActive ||
          productKey === products.find((e) => e.name.includes("Beach"))?.key ||
          productKey === products.find((e) => e.name.includes("Alagoas"))?.key
        }
        menu={{ items: teams, selectable: true, onClick: handleMenuClick }}
      >
        <Button
          id={isFormInfoActive ? "" : "buttonTeamDisabled"}
          style={{ width: "200px" }}
        >
          {showTeam(selectedTeam)}
          <CaretDownOutlined />
        </Button>
      </Dropdown> */}

      {/* <div id="infoContainer">
        <Input
          disabled={!isFormInfoActive}
          className="infoInput"
          addonBefore="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          disabled={!isFormInfoActive}
          className="infoInput"
          addonBefore="CPF"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
        <Accordion
          disabled={
            !isFormInfoActive ||
            productKey ===
              products.find((e) => e.name.includes("Beach"))?.key ||
            productKey === products.find((e) => e.name.includes("Alagoas"))?.key
          }
          expanded={isAccordionActive}
          onChange={() => setAccordionActive(!isAccordionActive)}
        >
          <AccordionSummary
            expandIcon={<DownOutlined />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <span className="accordionTitle">Cupom (opcional)</span>
            <span className="accordionSubtitle">
              Tem um cupom? Insira aqui!
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <Input.Search
              disabled={
                !isFormInfoActive ||
                coupon.isLoading ||
                productKey ===
                  products.find((e) => e.name.includes("Beach"))?.key ||
                productKey ===
                  products.find((e) => e.name.includes("Alagoas"))?.key
              }
              className="infoInput"
              id="couponInput"
              addonBefore="Cupom"
              enterButton="Aplicar"
              value={coupon.code}
              onSearch={handleCoupon}
              onChange={(e) =>
                setCoupon({
                  ...coupon,
                  code: e.target.value.toUpperCase().trim(),
                })
              }
              suffix={
                coupon.isValid === null ? (
                  coupon.isLoading ? (
                    <Tooltip placement="bottom" title="Carregando...">
                      <LoadingOutlined style={{ color: "blue" }} />
                    </Tooltip>
                  ) : (
                    <CloseOutlined style={{ color: "transparent" }} />
                  )
                ) : coupon.isLoading ? (
                  <Tooltip placement="bottom" title="Carregando...">
                    <LoadingOutlined style={{ color: "blue" }} />
                  </Tooltip>
                ) : coupon.isValid ? (
                  <Tooltip placement="bottom" title="Este cupom é válido">
                    <CheckOutlined style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="bottom" title="Cupom inválido">
                    <CloseOutlined style={{ color: "red" }} />
                  </Tooltip>
                )
              }
            />
          </AccordionDetails>
        </Accordion>
      </div> */}
      {/* {form.visible && (
        <div id="formContainer">
          <div className="formContent">
            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              type="password"
              addonBefore="Senha"
              value={form.senha}
              onChange={(e) => setForm({ ...form, senha: e.target.value })}
            />
            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              type="password"
              addonBefore="Confirmar Senha"
              value={form.verificarSenha}
              onChange={(e) =>
                setForm({ ...form, verificarSenha: e.target.value })
              }
            />
          </div>
          <div className="formContent">
            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              addonBefore="Nome"
              value={form.nome}
              onChange={(e) => setForm({ ...form, nome: e.target.value })}
            />

            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              addonBefore="Telefone"
              value={form.telefone}
              onChange={(e) => setForm({ ...form, telefone: e.target.value })}
            />
          </div>
          <div className="formContent">
            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              addonBefore="Endereço"
              value={form.endereco}
              onChange={(e) => setForm({ ...form, endereco: e.target.value })}
            />
            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              addonBefore="Número"
              value={form.numero}
              onChange={(e) => setForm({ ...form, numero: e.target.value })}
            />
          </div>
          <div className="formContent">
            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              addonBefore="Cidade"
              value={form.cidade}
              onChange={(e) => setForm({ ...form, cidade: e.target.value })}
            />
            <Input
              disabled={!isFormCreateActive}
              className="infoInput"
              addonBefore="CEP"
              value={form.cep}
              onChange={(e) => setForm({ ...form, cep: e.target.value })}
            />
            <Dropdown
              disabled={!isFormCreateActive}
              menu={{
                items: states,
                selectable: true,
                onClick: handleStateClick,
              }}
            >
              <Button
                id={isFormCreateActive ? "" : "buttonTeamDisabled"}
                style={{ width: "100%" }}
              >
                {showState(selectedState)}
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      )} */}

      {/* <Button
        id="nextButton"
        type="primary"
        style={!form.visible ? {} : { display: "none" }}
        loading={loadingNext}
        onClick={handleNextButton}
      >
        Próximo
      </Button>
      <Button
        id="nextButton"
        type="primary"
        style={form.visible ? {} : { display: "none" }}
        loading={loadingCheckout}
        onClick={handleCheckout}
      >
        Próximo
      </Button>
      <PopupCheckout
        open={popupOpen}
        setPopupOpen={setPopupOpen}
        link={apiResponse.link}
        price={apiResponse.price}
        discount={apiResponse.discount}
        priceWithDiscount={apiResponse.priceWithDiscount}
        productKey={productKey}
      /> */}
    </div>
  );
};

export default Main;
