import React from "react";
import style from "./style.module.sass";
import { WhatsAppOutlined } from "@ant-design/icons";
const FaleConosco = () => {
  const numeroTelefone = "+5579998050555";

  const handleClick = () => {
    const linkWhatsApp = `https://wa.me/${numeroTelefone}?text=${encodeURIComponent(
      ""
    )}`;
    window.open(linkWhatsApp, "_blank");
  };

  return (
    <>
      <WhatsAppOutlined
        width={"50px"}
        height={"50px"}
        onClick={handleClick}
        className={style.btn}
      />
    </>
  );
};

export default FaleConosco;
