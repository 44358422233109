import React from 'react';

import headerBanner from '../../images/header.png'

import './header.sass'

const Header = () => {
    return ( 
    <div id='headerContainer'>
        <img id='imgHeader' src={headerBanner} alt="headerBanner" />
    </div> 
    );
}
 
export default Header;