import React from "react";

import "./footer.sass";

const Footer = () => {
  const date = new Date();
  return (
    <div id="footerContainer">
      <span>
        &copy;{date.getFullYear()} ITTV Telecomunicações. Todos os direitos
        reservados.{" "}
      </span>
    </div>
  );
};

export default Footer;
