import { IRedirectProducts } from "../../utils/sergipao";
import "./imageCard.sass";

function importAll(r: any) {
  let images: any = {};
  r.keys().map((item: any, index: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../images/products/", false, /\.(png|jpe?g|svg|webp)$/)
);

export default function ImageCard(props: { data: IRedirectProducts }) {
  const { data } = props;

  return (
    <a href={data.path}>
      <div className="containerRedirectCard">
        <img
          className="imgRedirectCard"
          src={images[data.image + ".webp"]}
          alt={data.name}
        ></img>
        <div className="infoRedirectCard">
          <span className="priceRedirectCard">
            <span style={{fontSize: "1.5rem"}}>
              R${" "}
            </span>
            {data.price.toFixed(2).replace(".", ",")}
            <span
              style={{
                fontSize: "1.5rem",
              }}
            >
              /mês
            </span>
          </span>
        </div>
      </div>
    </a>
  );
}
